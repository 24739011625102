<template>
  <div class="grid-services px-3">
    <services-item v-for="(item, index) in dataServices.group_services" :key="index" :itemId="index+1" :name="item.title" :imgurl="item.img" :copy="item.text" ></services-item>
  </div>
</template>

<script>
import ServicesItem from '@/components/services/servicesItem'
import placeHolderImg from "@/assets/img/testimonial-1.jpg";
export default {
  components:{
    ServicesItem
  },
  props:{
    dataServices: Object
  },
  data(){
    return{
        items: [
        {
          itemId:1,
          name: "Interior Design",
          imgurl: placeHolderImg
        },
        {
          itemId:2,
          name: "Creative Direction",
          imgurl: placeHolderImg
        },
        {
          itemId:3,
          name: "Styling",
          imgurl: placeHolderImg
        },
        {
          itemId:4,
          name: "Design",
          imgurl: placeHolderImg
        },
        {
          itemId:5,
          name: "Build",
          imgurl: placeHolderImg
        },
        {
          itemId:6,
          name: "Project Management",
          imgurl: placeHolderImg
        }
      ]
    }
  }
};
</script>

<style>
</style>