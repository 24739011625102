<template>
  <div class="grid-services__item" :class="'grid-services__item--'+itemId" data-aos="fade-up">
    <img :src="imgurl" alt class="grid-services__img" />
    <h3 class="title">
      {{ name }}
    </h3>
    <div class="parent-decription">
      <div class="description-container">
        <h3>{{ name }}</h3>
        <div class="copy">{{copy}}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    name: String,
    itemId: Number,
    imgurl: String,
    copy: String
  },
  data(){
    return{
      isOpen: true
    }
  }
};
</script>

<style>
</style>