<template>
<layout-white>
   <section v-if="dataErrored">
      <p>We're sorry, we're not able to retrieve this information at the moment, please try back later</p>
    </section>
  <div v-else class="layout__services position-relative overflow-hidden">
      <div id="B" class="letter">
        <img src="@/assets/img/texture1.jpg" class="letter__img" :style="{ 'mask-image':maskimgB }" />
      </div>
    <div class="container-fluid position-relative z-index-5">
      <div class="row">
        <div class="col-12  offset-lg-1 col-lg-4 mt-10">
          <h1 class="text-white" v-if="itLoads">{{pageData.title.rendered}}</h1>
          <transition name="fade" v-show="itLoads">
          <div class="w-100 py-5 tx-c-tertiary" v-if="itLoads" v-html="pageData.content.rendered">
          </div>
          </transition>
        </div>
      </div>
    </div>
    <div class="container-fluid mt-5 py-5 position-relative z-index-5">
      <div class="row py-5">
        <div class="col-12">
          <services-grid v-if="itLoads" :dataServices="pageData.acf"/>
        </div>
      </div>
    </div>
  </div>
  </layout-white>
</template>

<script>
import ServicesGrid from "@/components/services/servicesGrid"
import imgMaskB from "@/assets/img/b.svg";
import LayoutWhite from '@/layouts/LayoutWhite';
export default {
  inject:['MyLoading'],
  components: {
    ServicesGrid, LayoutWhite
  },
  data() {
    return {
      maskimgB: "url(" + imgMaskB + ")",
      pageData:{
        title:{
          rendered: 'Services'
        }
      },
      itLoads: false,
      dataLoading: true,
      dataErrored: false,
    };
  },
  head() {
    if(this.dataLoading == false) {
      const metaArray = [];
      this.pageData.yoast_meta.map(ele => {
        metaArray.push({
          hid: ele.name ? ele.name : ele.property,
          name: ele.name ? ele.name : ele.property,
          content: ele.content,
        });
      });

      return {
        title: this.dataLoading ? 'RBD' : this.pageData._yoast_wpseo_title,
        meta: metaArray,
      }
    }
  },
   methods: {
    fetchingData() {
      this.$axios
        .get(this.$axios.defaults.baseURL + "wp/v2/pages/32")
        .then(response => {
          this.pageData = response.data;
          this.itLoads = true;
          this.MyLoading.val = false;
        })
        .catch((error) => {
          console.log(error);
          this.dataErrored = true
        }).finally(() => this.dataLoading = false);
    },
   },
   mounted() {
    this.fetchingData();
  },
  created() {
    this.MyLoading.val = true;
  }
};
</script>
